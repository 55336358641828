/**
 * 娱乐区 - 游戏大厅枚举定义
 */

// 出入金类型
export enum typeCdEnum {
  /**  从第三方转入我的钱包 */
  in = 'in',
  /**  从我的钱包转出到第三方 */
  out = 'out',
}

// 自动转账模式枚举
export enum preferenceEnum {
  /** 启用 */
  enable = 'enable',
  /** 禁用 */
  disable = 'disable',
}

// 时间周期枚举值
export enum timePeriodEnum {
  /** 未选中 */
  out = -1,
  /** 全部 */
  all = 0,
  /** 今日 */
  today = 1,
  /** 昨日 */
  yesterday = 2,
  /** 最近三月 */
  theLastWeek = 90,
  /** 最近一月 */
  theLastMonth = 4,
}

/** 字典元素 */
export type CodeType = {
  codeKey: string
  codeVal: string
}

export type ICoinRate = {
  /** 币种对应 usdt 汇率 */
  usdtRate: string
  /** 币种 */
  symbol: string
  /** 币种 id */
  coinId: string
  /** 币种展示精度 */
  coinPrecision: number
}

export type ILegalCurrencyRate = {
  /** 法币 */
  currency: string
  /** 汇率 */
  rate: string
}

/**
 * 游戏记录 - 结算状态
 */
export enum SettlementEnum {
  /** 进行中 */
  Ongoing = 'ing',
  /** 未结算 */
  NotSettled = '0',
  /** 结算中 */
  Billing = '4',
  /** 赢 */
  win = '1',
  /** 异常冻结 */
  ExceptionallyFrozen = 'frozen',
  /** 输 */
  lose = '2',
  /** 未知状态 */
  UnknownStatus = '5',
}

/**
 * 返水 - 明细状态
 * */
export enum DetailStatusEnum {
  /** 成功 */
  success = 'success',
  /** 失败 */
  fail = 'fail',
}

/**
 * 法币枚举值
 */

export enum CurrencyeEnumeration {
  /** 全部 */
  all = '0',
}

/**
 * 返水佣金 tab 枚举值
 */

export enum BetrayalEnum {
  /** 返水 */
  betrayal = 1,
  /** 明细 */
  details = 2,
}

/**
 * 返水状态枚举值
 * */
export enum EnumerationValueEnum {
  /** 绿色 */
  TabelGreen = 'tabel-green',
  /** 红色 */
  TabelRed = 'tabel-red',
  /** 橙色 */
  TabelAuxiliary = 'tabel-auxiliary',
}

/**
 * 划转账户维护状态枚举
 */
export enum MaintainEnumeration {
  /** 正常 */
  Yes = 'yes',
  /** 维护中 */
  No = 'no',
}

/**
 * 游戏类型维护枚举值
 */
export enum MaintainsEnumeration {
  /** 维护中 */
  Maintenance = 'maintenance',
  /** 正常 */
  Normal = 'normal',
}

/** 我的投注 - 结算 */
export enum SettlementEnumeration {
  /** 未结算 */
  unsettled = 'unsettled',
  /** 已结算 */
  settled = 'settled',
}

/** 游戏详情-试玩模式 */
export enum GameModelTypeEnum {
  /** 支持 */
  support = 1,
  /** 不支持 */
  noSupport = 2,
}
